<template>
	<div class="general">
		<v-snackbar v-model="copied" :timeout="2000" top color="purple"> Link Copied Successfully. </v-snackbar>

		<v-row>
			<v-col cols="12">
				<v-card>
					<v-card-text class="py-3">
						<h3 class="fs--22 fw--400">Browser Media Source URL</h3>
						<span class="fieldFore--text fs--12 fw--400">
							Choose your streaming software to implement the Stream Stickers browser source.
						</span>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="6" class="mb-5">
				<v-card height="100%">
					<v-card-text class="text-center">
						<div class="mt-5">
							<img src="@/assets/icons/source/obspng.png" width="90" alt="OBS" />

							<v-btn
								block
								depressed
								:outlined="media === 2"
								rounded
								color="pink"
								class="text-capitalize mt-5"
								@click="media = 1"
								>I use OBS Studio</v-btn
							>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="6" class="mb-5">
				<v-card height="100%">
					<v-card-text>
						<div class="d-flex flex-wrap align-center justify-center mt-5">
							<img src="@/assets/icons/source/slpng.png" width="95" alt="SL" />
							<img src="@/assets/icons/source/splitpng2.png" width="95" alt="Split" />
							<img src="@/assets/icons/source/LightStream.png" width="95" alt="LightStream" />
							<img src="@/assets/icons/source/TwitchStudio.png" width="95" alt="TwitchStudio" />
						</div>

						<v-btn
							block
							depressed
							:outlined="media === 1"
							rounded
							color="pink"
							class="text-capitalize mt-5"
							@click="media = 2"
							>I use something else</v-btn
						>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<v-card v-if="media === 1" class="mt-2">
			<v-card-text>
				<section class="pb-4">
					<h3 class="fs--18 fw--400 mb-2">Drag and Drop the button below in your OBS starting scene.</h3>
					<draggable>
						<v-btn
							:href="browserSourceLink"
							rounded
							depressed
							color="pink"
							class="text-capitalize drag-source"
							>Drag &amp; Drop this button into OBS</v-btn
						>
					</draggable>

					<div class="mt-2">
						<span class="white--text fs--12 fw--400">
							<strong>Important:</strong> After adding the above Browser Source to your starting scene, copy and paste it into your other scenes in OBS instead of creating a new Browser Source for each scene. This will prevent audio duplication.
						</span>
						<a class="fs--12 pink--text" href="https://discord.com/invite/2ePbGrc" target="_blank"
							>Need Help?</a
						>
					</div>
				</section>
			</v-card-text>
		</v-card>

		<v-card v-if="media === 2" class="mt-2">
			<v-card-text>
				<section class="pb-4">
					<h3 class="fs--22 fw--400 mb-2">Browser Media Source URL</h3>
					<v-row no-gutters>
						<v-col cols="8" sm="9" lg="10" xl="11" class="pr-3 source-url">
							<template v-if="false">
								<v-text-field
									v-model="browserSourceLink"
									disabled
									background-color="purple"
									color="fieldFore"
									dense
									flat
									solo
									hide-details>
								</v-text-field>
							</template>
							<template v-else>
								<v-text-field
									disabled
									background-color="purple"
									color="fieldFore"
									dense
									flat
									solo
									hide-details>
									<template #label>
										<div class="d-flex">
											<span :class="blur && 'blury-link'">{{ browserSourceLink }}</span>
										</div>
									</template>
								</v-text-field>
								<span
									v-if="blur"
									@click="actions.removeBlur"
									class="unblur-text cursor-pointer white--text fs--12"
									><v-icon size="18" color="white">{{ icons.eye }}</v-icon> Click here to show the
									URL</span
								>
							</template>
						</v-col>
						<v-col cols="4" sm="3" lg="2" xl="1">
							<v-btn depressed color="primary" block @click="actions.copy">Copy</v-btn>
						</v-col>
					</v-row>
					<div class="mt-2">
						<span class="fieldFore--text fs--12 fw--400">
							Copy this link into a browser source of every scene of your broadcast software before using
							Stream Stickers.
						</span>
						<a class="fs--12 pink--text" href="https://discord.com/invite/2ePbGrc" target="_blank"
							>Need Help?</a
						>
					</div>
				</section>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api';
import { getEndpointHost } from '../../../utils/config';
import { getUserId } from '../../../utils/auth';
import { mdiEye } from '@mdi/js';

export default {
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	emits: ['onRangeDataStart', 'onRangeDataEnd'],
	setup(props) {
		const media = ref(1);
		const blur = ref(true);
		let dragged = ref(null);

		onMounted(() => {
			actions.onDrag();
		});
		const actions = {
			// Copy the OBS URL to the clipboard.
			copy: () => {
				navigator.clipboard.writeText(browserSourceLink).then(() => {
					copied.value = true;
					setTimeout(() => {
						copied.value = false;
					}, 2000);
				});
			},
			onDrag: () => {
				const pos = 30;

				// Add an event listener
				document.addEventListener('dragstart', e => {
					dragged.value = e.target;
					// Customize the visible 'thumbnail' while dragging
					e.dataTransfer.setDragImage(document.querySelector('#dragImage'), pos, pos);
					// Set the data type, and the value. You specifically want text/uri-list.
					e.dataTransfer.setData('text/uri-list', dragged.value.href);

					document.addEventListener('dragend', e => dragged.value.blur());
				});
			},
			prevent: e => {
				e.preventDefault();
			},
			removeBlur: () => {
				blur.value = false;
			},
		};

		const browserSourceLink = `https://${getEndpointHost()}:8081/stickers/${getUserId()}/mt`;
		const copied = ref(false);

		return {
			media,
			blur,
			actions,
			browserSourceLink,
			copied,
			icons: {
				eye: mdiEye,
			},
		};
	},
};
</script>

<style lang="scss" scoped>
.secondary-outline {
	border-color: var(--v-secondary-base) !important;
	background-color: transparent !important;
}

.drag-source:hover {
	cursor: move;
}

.drag-source {
	cursor: move;
}

.source-url {
	position: relative;
}

.unblur-text {
	position: absolute;
	top: 10px;
	right: 20px;
}

.blury-link {
	color: transparent;
	text-shadow: 0 0 4px rgba(255, 255, 255, 0.5) !important;
}
</style>
