<template>
	<div class="tts-settings">
		<v-snackbar v-model="copied" :timeout="2000" top color="purple">Link Copied Successfully.</v-snackbar>
		<v-card>
			<v-card-text>
				<section class="pb-4">
					<h3 class="fs--22 fw--400">Text-to-Speech Settings</h3>
					<div class="d-flex align-end mb-2">
						<v-switch
							v-model="config.ttsAttrs.isManuallyApproveAllStickers"
							color="pinkItem"
							hide-details></v-switch>
						<strong class="fs--14 fw--400 pl-4">Manually approve all TTS Stickers</strong>
					</div>
					<p class="fieldFore--text fs--12 fw--400 mb-2">
						When enabled broadcasters can manually approve all TTS stickers via the Activity Feed on their
						dashboard prior to the sticker appearing.
					</p>
					<p class="fieldFore--text fs--12 fw--400 mb-2">
						Broadcasters will have 60 minutes to approve TTS messages before they automatically expire.
					</p>
					<p class="fieldFore--text fs--12 fw--400 mb-2">
						If manual moderation is enabled, we highly recommend using the activity feed
						<span class="pink--text">"pop-out"</span>
						feature to view your activity feed while you stream so messages do not go missed.
					</p>
					<p class="fieldFore--text fs--12 fw--400 mb-2">
						Your activity feed can also be added as a Dock directly inside OBS using the
						<span class="pink--text">"Custom Browser Docks"</span>
						feature. To activate, just add this URL to a custom dock inside OBS:
						<br />
						<span class="pink--text cursor-pointer" @click="copy">{{ activity_feed_url }}</span>
					</p>
					<p class="fieldFore--text fs--12 fw--400 mb-0">
						Have questions? Join our
						<a class="pink--text cursor-pointer" href="https://discord.com/invite/2ePbGrc" target="_blank">
							Discord
						</a>
						.
					</p>
				</section>

				<v-divider class="purpleDivider"></v-divider>

				<section class="pb-4">
					<div class="d-flex align-end mb-2">
						<v-switch
							v-model="config.ttsAttrs.isPreventAudioOverlap"
							color="pinkItem"
							hide-details></v-switch>
						<strong class="fs--14 fw--400 pl-4">Prevent TTS Sticker Audio From Overlapping</strong>
					</div>
					<p class="fieldFore--text fs--12 fw--400 mb-2">
						When enabled, this feature will prevent the audio from two TTS Stickers from overlapping.
					</p>
					<p class="fieldFore--text fs--12 fw--400 mb-0">
						Please note this will not prevent overlap between other third-party TTS Systems such as Stream
						Labs, or Stream Elements.
					</p>
				</section>

				<v-divider class="purpleDivider"></v-divider>

				<!-- Hide accent enhancement due to EL model change -->
				<!--
				<section class="pb-4">
					<div class="d-flex align-end mb-2">
						<v-switch
							v-model="config.ttsAttrs.isAccentEnhancement"
							color="pinkItem"
							hide-details></v-switch>
						<strong class="fs--14 fw--400 pl-4"> Default Accent Enhancement for Global A.I. Voices </strong>
					</div>
					<p class="fieldFore--text fs--12 fw--400 mb-2">
						Enable this option to enhance the TTS voice performance of Global A.I. Voices on non-English
						speaking Channels. This can also help improve the performance of TTS Voices with accents.
					</p>
				</section>

				<v-divider class="purpleDivider"></v-divider>
				-->

				<section>
					<h3 class="fs--16 fw--400 mt-3 mb-4">Global TTS Display Size</h3>
					<div class="mb-4">
						<v-item-group v-model="globalTtsDisplaySize" active-class="secondary" mandatory>
							<v-item v-slot="{ active, toggle }">
								<v-btn
									small
									class="mx-1 mx-sm-2"
									:class="!active ? 'secondary-outline' : 'secondary'"
									:outlined="!active"
									depressed
									@click="toggle">
									<span>Regular</span>
								</v-btn>
							</v-item>
							<v-item v-slot="{ active, toggle }">
								<v-btn
									small
									:outlined="!active"
									:class="!active ? 'secondary-outline' : 'secondary'"
									depressed
									@click="toggle">
									<span>Large</span>
								</v-btn>
							</v-item>
						</v-item-group>
					</div>
					<p class="fieldFore--text fs--12 fw--400 mb-2">
						This setting will adjust how large our Global A.I. TTS Stickers appear on screen.
						<span style="font-weight: bold">Note:</span>
						This setting will not impact your Custom A.I. TTS stickers which have a max size of 650x650
						based on the images uploaded or any A.I. TTS stickers with custom placement enabled, which are
						automatically displayed at 350x350.
					</p>
				</section>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { computed, ref } from '@vue/composition-api';
import { GlobalTtsDisplaySize } from '../../../utils/constants';
import { mdiAlertCircleOutline } from '@mdi/js';

export default {
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const copied = ref(false);
		const activity_feed_url = 'https://dashboard.streamstickers.com/activity-dashboard';

		const copy = () => {
			navigator.clipboard.writeText(activity_feed_url).then(() => {
				copied.value = true;
				setTimeout(() => {
					copied.value = false;
				}, 2000);
			});
		};

		// Toggle buttons are index-based.
		const globalTtsDisplaySize = computed({
			get() {
				let modelValue = GlobalTtsDisplaySize.REGULAR;
				if (props.config && props.config.ttsAttrs.globalTtsDisplaySize) {
					modelValue = props.config.ttsAttrs.globalTtsDisplaySize;
				}

				return modelValue == GlobalTtsDisplaySize.LARGE ? 1 : 0;
			},
			set(newValue) {
				if (newValue === 1) {
					props.config.ttsAttrs.globalTtsDisplaySize = GlobalTtsDisplaySize.LARGE;
				} else {
					props.config.ttsAttrs.globalTtsDisplaySize = GlobalTtsDisplaySize.REGULAR;
				}
			},
		});

		return {
			globalTtsDisplaySize,
			activity_feed_url,
			copy,
			copied,
			icons: {
				mdiAlertCircleOutline,
			},
		};
	},
};
</script>
